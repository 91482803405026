/* You can add global styles to this file, and also import other style files */
$primary-color: #1181b2
$forma-blue: #1E9CB9
$secondary-color: #212221

$accent-color-500: #ddedf4
$accent-color-600: #f2e6d9

$primary-text-color: #D2EBF1
$unawa-orange: #FF9F00
$signsecure-orange: #F89E1B
$forma-blue: #1E9CB9

$black: #4b4b4b
$dms-black: #262E37

$gradient: linear-gradient(180deg, #F4FBFF 0%, #DBE8F9 100%)

$gray-color-100: #ffffff
$gray-color-200: #f5f5f5
$gray-color-300: #ebebeb
$gray-color-400: #cccccc
$gray-color-500: #a3a3a3
$gray-color-600: #8f8f8f
$gray-color-700: #7a7a7a
$gray-color-800: #666666
$gray-color-900: #525252

button
    cursor: pointer

.mat-progress-spinner circle, .mat-spinner circle
    stroke: var(--primary-color, $primary-color) !important

// .mat-checkbox-checked .mat-checkbox-checkmark-path
//     stroke: var(--primary-color) !important

.mat-checkbox-checked.mat-accent .mat-checkbox-background
    background-color: var(--primary-color) !important
//    border: 2px solid var(--primary-color)
    border-radius: 5px
.mat-checkbox-frame
    border-color: var(--primary-color)
    border-radius: 5px !important
/*** TO USE MIXIN **
// Import this sass
// to call @include for-size(phone-only) {    font-size: 1rem; }
// must be inside a parent class
// Example
// .header 
//      font-size: 2rem
//      @include for-size(phone-only) {    font-size: 1rem; }

@mixin for-size($size)
    @if $size == phone-only
        @media (max-width: 600px)
            @content
    @else if $size == tablet-portrait-up
        @media (min-width: 600px) and (max-width: 1368px)
            @content
    @else if $size == tablet-landscape-up
        @media (min-width: 912px)
            @content
    @else if $size == desktop-up
        @media (min-width: 1200px)
            @content
    @else if $size == desktop-only
        @media (min-width: 1080px) and (max-width: 1919px)
            @content
    @else if $size == big-desktop-up
        @media (min-width: 1920px)
            @content
    @else if $size == mac-default
        @media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)
            @content
        
@function addOpacity($color, $opacity) 
    @return $color + $opacity

.margin-container
    padding-left: 104px
    padding-right: 90px

@include for-size('desktop-only')
    .margin-container
        padding-left: 93.6px
        padding-right: 81px


::ngdeep .mat-form-field-appearance-fill .mat-form-field-flex
    padding: 0 0 0 0 
    border-top: 0 
::ng-deep .mat-form-field-wrapper
    padding-bottom: 0
    .mat-form-field-flex
        padding: 0 10px 0 10px
        .mat-form-field-infix
            padding: 0 0 0 0 
            border-top: 0 
::ng-deep .mat-tooltip
    background: var(--primary-color, $forma-blue) !important